import { Avatar, Button, colorTokens, useOpenChat, useTranslate } from '@orus.eu/pharaoh'

export function HeaderContactButton(): JSX.Element {
  const openChat = useOpenChat()
  const translate = useTranslate()
  return (
    <Button
      variant="secondary"
      onClick={openChat}
      avatar={
        <Avatar
          icon="comment-question-duotone"
          color={colorTokens['color-fg-base-active-inverse']}
          secondaryColor={colorTokens['color-fg-base-active-inverse']}
        />
      }
      size="medium"
      avatarPosition="left"
      trackingId="contact_button"
    >
      {translate('contact_customer_support')}
    </Button>
  )
}
